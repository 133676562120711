import { mapActions, mapGetters } from 'vuex'
import CInputDateRange from '@/components/CInputDateRange'
import permissions from '@/utils/constants/permissions'
import GCFilter from '@/components/GCFilter/index'
import CPagination from '@/components/CPagination'
import Gate from '@/services/Gate/Gate'
import { notifyByPlan } from '@/services/Notify/Toasts'
import onTimeoutSearch from '@/mixin/searchTimeout'

export default {
  name: 'CompaniesList',
  mixins: [onTimeoutSearch],
  inject: ['toast'],
  components: { CInputDateRange, GCFilter, CPagination },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Company Name',
          _style: '',
          link: { name: 'CompanyInfo', params: { id: 'id' }, iconNewTab: true },
        },
        {
          key: 'type',
          label: 'Company Type',
          _style: '',
          format: (item) => item?.capitalize(),
        },
        {
          key: 'projects_count',
          label: 'Project',
          _style: '',
          link: { name: 'CompanyProjects', params: { id: 'id' } },
          format: (v) => `${v}`,
        },
        {
          key: 'tasks_count',
          label: 'Tasks',
          _style: '',
          link: { name: 'CompanyTasks', params: { id: 'id' } },
          format: (v) => `${v}`,
        },
        {
          key: 'tasks_overdue_count',
          label: 'Tasks Overdue',
          _style: '',
          link: {
            name: 'CompanyTasks',
            params: { id: 'id' },
            query: { is_overdue: true },
          },
          format: (v) => `${v}`,
        },
        { key: 'primary_contact_name', label: 'Contact Name', _style: '' },
        {
          key: 'primary_contact_email',
          label: 'Contact Email',
          _style: '',
          email: true,
        },
        { key: 'primary_contact_phone', label: 'Contact Mobile', _style: '' },
      ],
      key: 0,
      activePage: 1,
      pages: 1,
      perPage: '10',
      sort: { column: '', state: '' },
      search: '',
      searchDate: {},
      loadingTable: false,
      selected: [],
      selectedCompanies: [],
    }
  },
  computed: {
    ...mapGetters(['companies']),
    canSendEmail() {
      return this.authUser.can(permissions.COMPANIES_COMMUNICATION)
    },
  },
  watch: {
    pages() {
      if (this.activePage > this.pages) {
        this.activePage = this.activePage - 1
      }
    },
    perPage() {
      this.activePage = 1
      this.fetchData && this.fetchData()
    },
    activePage() {
      if (this.activePage > this.pages) {
        this.activePage = this.pages
      } else if (this.activePage <= 0) {
        this.activePage = 1
      } else {
        this.fetchData && this.fetchData()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.applyPrefiltering()
      if (!this.$route.query.search) this.fetchData()
    })
  },
  methods: {
    ...mapActions(['fetchCompanies']),
    onSelect(companies) {
      this.selectedCompanies = companies
      this.$emit('onSelect', this.selectedCompanies)
    },
    async handleSendEmail() {
      const check = await Gate.can('communication', 'employee')
      if (!check) {
        notifyByPlan()
        return false
      }
      this.$router.push({
        name: 'SendCommunication',
        query: {
          users: this.selectedCompanies,
          field: 'company_id'
        },
      })
    },
    onSort(sort) {
      this.sort = sort
      this.fetchData && this.fetchData()
    },
    applyPrefiltering() {
      if (
        this.$route.query &&
        this.$route.query.prefilter &&
        Object.keys(this.$route.query).some((param) =>
          param.match(/^filter\[.+\]$/),
        )
      ) {
        const query = this.$route.query
        Object.keys(this.$route.query)
          .filter((param) => param.match(/^filter\[.+\]$/))
          .forEach((key) => {
            let val = query[key]
            let normalizedKey = key.replace('filter[', '').replace(']', '')
            if (this[normalizedKey] !== undefined) {
              this[normalizedKey] = val
            }
          })
      }
    },
    openInNewTab(id) {
      let routeData = this.$router.resolve({
        name: 'CompanyInfo',
        params: { id: id },
      })
      window.open(routeData.href, '_blank')
    },
    async eventRemoveCompany() {
      const check = await Gate.can('deleteList', 'company')
      if (!check) {
        notifyByPlan()
        return false
      }
      const x = this.selectedCompanies
        .map((id) => this.companies.find((i) => i.id === id))
        .filter((c) => c.projects_count)
        .map((i) => i.name)
      if (x.length) {
        this.toast(
          'error',
          'Please delete all scopes assigned to company:' +
            ' ' +
            x.join(', ') +
            ' ' +
            'before removing it',
        )
        return
      }
      this.$http.companies
        .removeCompany({ company_id: this.selectedCompanies })
        .then(() => {
          this.toast('success', 'The company has been removed from list successfully')
          this.fetchData()
        })
    },
    fetchData(f = {}) {
      this.search = f.search || this.$route.query.search || this.search
      this.loadingTable = true
      this.fetchCompanies({
        params: {
          ...f,
          per_page: this.perPage,
          page: this.activePage,
          sort_field: this.sort && this.sort.column,
          sort_direction: this.sort && this.sort.state,
          with_current: 1,
        },
      })
        .then((res) => {
          this.selectedCompanies = []
          this.pages = res.meta.last_page
          this.loadingTable = false
        })
        .catch(() => {
          this.loadingTable = false
        })
    },
    setCheck(value) {
      this.selectedCompanies = value.map((i) => i.id)
    },
    exportCompanies() {
      window.open(
        `${
          process.env.VUE_APP_API_URL
        }${this.$http.companies.exportCompany()}?companies[]=${this.selectedCompanies.join(
          '&companies[]=',
        )}`,
      )
    },
  },
}
