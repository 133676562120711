<template>
  <CCard class="CompanyList">
    <CCardBody>
      <div
        style="
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          flex-wrap: wrap;
        "
      >
        <CRow class="gx-0 gx-lg-3 justify-content-start w-100">
          <CCol
            class="GCFilterMaxWidth col-12 col-md-12 col-lg-9 col-xl-10 col-xxl-10"
          >
            <GCFilter
              component="Company"
              :except-column="[]"
              @search="(val) => onTimeoutSearch(() => fetchData(val))"
            />
          </CCol>

          <CCol
            class="bulkAction col-12 col-md-12 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0"
          >
            <CDropdown
              :key="`${canSendEmail}-${selectedCompanies.length}`"
              color="primary"
              style="margin-right: 10px; margin-bottom: 10px"
              class="w-100"
            >
              <CDropdownToggle
                color="primary"
                variant="outline"
                :disabled="!selectedCompanies.length"
              >
                Bulk Action
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="exportCompanies">
                  Export Companies
                </CDropdownItem>
                <CDropdownItem
                  @click="handleSendEmail"
                >
                  Send Email
                </CDropdownItem>
                <CDropdownItem @click="eventRemoveCompany">
                  Remove
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCol>
        </CRow>
      </div>

      <SmartTable
        :items="companies"
        :items-per-page="parseInt(perPage)"
        :columns="fields"
        :sorter-value="sort"
        selectable="id"
        :selected-items="selectedCompanies"
        :loading="loadingTable"
        :active-page="activePage"
        :per-page="perPage"
        :pages="pages"
        :clear-text="search.length ? 'No search results found' : 'No Data yet'"
        @sorter-change="onSort"
        @selected-items-change="onSelect"
        @update-active-page="(value) => (activePage = value)"
        @update-per-page="(value) => (perPage = value)"
      />
    </CCardBody>
  </CCard>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
